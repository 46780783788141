import { FiEdit3 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useEffect, useState } from "react";
import {
  fetchRestaurantProfile,
  setProfile,
  updateRestaurant,
} from "../../redux/slices/restaurantProfileSlice";
import { setError } from "../../redux/slices/reviewRequestSlice";
import { Spin } from "antd";

type Props = {};

const RestaurantProfile = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const restaurant = useSelector((state: RootState) => state.restaurantProfile);

  const [updatedRestaurant, setUpdatedRestaurant] = useState({ ...restaurant });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await dispatch(fetchRestaurantProfile()).unwrap();
        dispatch(setProfile(data));
      } catch (error) {
        dispatch(setError(error));
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (!isModalOpen) {
      setUpdatedRestaurant({ ...restaurant });
      setImageFile(null);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdatedRestaurant((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImageFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    const hasChanges =
      JSON.stringify(updatedRestaurant) !== JSON.stringify(restaurant) ||
      imageFile !== null;
    setIsChanged(hasChanges);
  }, [updatedRestaurant, restaurant, imageFile]);

  const handleSubmit = async () => {
    if (!isChanged) return;
    try {
      await dispatch(
        updateRestaurant({ restaurant: updatedRestaurant, imageFile })
      ).unwrap();
      setIsModalOpen(false);
    } catch (error) {
      dispatch(setError(error));
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Spin size="large" />
      </div>
    );
  };

  return (
    <div className="flex flex-col justify-between min-h-full">
      <div>
        <div className="py-6">
          <h2 className="text-2xl font-medium">Profili i Restaurantit</h2>
          <p className="text-sm">
            Këtu mund t’i shihni të gjitha të dhënat e restorantit tuaj të
            krijuara nga Jomuntu. Po ashtu, keni mundësinë t’i modifikoni ato.
          </p>
        </div>
        <div className="flex items-start justify-between">
          <div className="">
            <h2 className="text-sm font-medium">Logo e Restaurantit</h2>
            <div className="flex pt-2">
              <img
                className="mt-1 w-28 h-28"
                src={restaurant.logoUrl}
                alt="logo"
              />
            </div>
          </div>
          <button
            onClick={toggleModal}
            className="bg-customOrange w-[189px] text-white h-[36px] rounded-lg text-sm flex items-center gap-3 justify-center"
          >
            Editoni te dhenat{" "}
            <span>
              <FiEdit3 />
            </span>
          </button>
        </div>
        <div className="flex w-full gap-4 pt-4">
          <div className="w-full">
            <h2 className="text-sm font-medium">Emri i Restaurantit</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">{restaurant.name}</p>
            </div>
          </div>
          <div className="w-full">
            <h2 className="text-sm font-medium">Kategoria e Restaurantit</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">{restaurant.category}</p>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-4 pt-6">
          <div className="w-full">
            <h2 className="text-sm font-medium">Emri i Pronarit</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">{restaurant.ownerName}</p>
            </div>
          </div>
          <div className="w-full">
            <h2 className="text-sm font-medium">Email Adresa e Pronarit</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">{restaurant.ownerEmail}</p>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-4 pt-6">
          <div className="w-full">
            <h2 className="text-sm font-medium">Adresa</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">{restaurant.location}</p>
            </div>
          </div>
          <div className="w-full">
            <h2 className="text-sm font-medium">Numri Kontaktues</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">{restaurant.phoneNumber}</p>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-4 pt-6">
          <div className="w-full">
            <h2 className="text-sm font-medium">Email Adresa</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">{restaurant.emailAddress}</p>
            </div>
          </div>
          <div className="w-full">
            <h2 className="text-sm font-medium">Orari i Punes</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">{restaurant.openingHours}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end w-full gap-4 pt-8">
        <h1 className="text-sm">
          Së shpejti do të mund të shihni si duket profili juaj në aplikacion.
        </h1>
        <button className="w-[155px] bg-[#EEEEEE] h-[36px] rounded-lg text-sm font-medium">
          Preview profile
        </button>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-8/12 p-6 bg-white rounded-lg h-8/12">
            <h2 className="mb-4 text-xl font-medium">Edito Profilin</h2>
            {/* ROW 1 */}
            <div className="flex ">
              <div className="w-6/12 mb-4">
                <label className="block text-sm font-medium">Imazhi</label>
                <input
                  type="file"
                  accept="image/*"
                  className="mt-2"
                  onChange={handleImageChange}
                />
                {imageFile ? (
                  <img
                    src={URL.createObjectURL(imageFile)}
                    alt="New Preview"
                    className="w-20 h-20 mt-2 border rounded-md"
                  />
                ) : (
                  <img
                    src={restaurant.logoUrl}
                    alt="Current Logo"
                    className="w-20 h-20 mt-2 border rounded-md"
                  />
                )}
              </div>
              <div className="w-6/12">
                <div className="w-full mb-4">
                  <label className="block text-sm font-medium">
                    Emri i Restaurantit
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={updatedRestaurant.name}
                    onChange={handleInputChange}
                    className="w-full p-2 mt-2 border rounded-md outline-none"
                  />
                </div>
                <div className="w-full mb-4">
                  <label className="block text-sm font-medium">
                    Kategoria e Restaurantit
                  </label>
                  <input
                    type="text"
                    name="category"
                    value={updatedRestaurant.category}
                    onChange={handleInputChange}
                    className="w-full p-2 mt-2 border rounded-md outline-none"
                  />
                </div>
              </div>
            </div>
            {/* ROW 2 */}
            <div className="flex gap-2">
              <div className="w-6/12 mb-4">
                <label className="block text-sm font-medium">
                  Emri i pronarit
                </label>
                <input
                  type="text"
                  name="ownerName" // Match the property in updatedRestaurant
                  value={updatedRestaurant.ownerName}
                  onChange={handleInputChange}
                  className="w-full p-2 mt-2 border rounded-md outline-none"
                />
              </div>
              <div className="w-6/12 mb-4">
                <label className="block text-sm font-medium">
                  Email i pronarit
                </label>
                <input
                  type="text"
                  name="ownerEmail"
                  value={updatedRestaurant.ownerEmail}
                  onChange={handleInputChange}
                  className="w-full p-2 mt-2 border rounded-md outline-none"
                />
              </div>
            </div>
            {/* ROW 3 */}
            <div className="flex gap-2">
              <div className="w-6/12 mb-4">
                <label className="block text-sm font-medium">Adresa</label>
                <input
                  type="text"
                  name="location"
                  value={updatedRestaurant.location}
                  onChange={handleInputChange}
                  className="w-full p-2 mt-2 border rounded-md outline-none"
                />
              </div>
              <div className="w-6/12 mb-4">
                <label className="block text-sm font-medium">
                  Numri Kontaktues
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={updatedRestaurant.phoneNumber}
                  onChange={handleInputChange}
                  className="w-full p-2 mt-2 border rounded-md outline-none"
                />
              </div>
            </div>
            {/* ROW 4 */}
            <div className="flex gap-2">
              <div className="w-6/12 mb-4">
                <label className="block text-sm font-medium">
                  Email adresa
                </label>
                <input
                  type="text"
                  name="emailAddress"
                  value={updatedRestaurant.emailAddress}
                  onChange={handleInputChange}
                  className="w-full p-2 mt-2 border rounded-md outline-none"
                />
              </div>
              <div className="w-6/12 mb-4">
                <label className="block text-sm font-medium">
                  Orari i punes
                </label>
                <input
                  type="text"
                  name="openingHours"
                  value={updatedRestaurant.openingHours}
                  onChange={handleInputChange}
                  className="w-full p-2 mt-2 border rounded-md outline-none"
                />
              </div>
            </div>
            <div className="flex justify-end gap-4">
              <button
                onClick={toggleModal} // Close modal
                className="px-6 py-1 text-sm bg-gray-300 rounded-md "
              >
                Close
              </button>
              <button
                onClick={handleSubmit}
                disabled={!isChanged}
                className={`px-6 p-2 rounded-lg text-sm ${
                  isChanged
                    ? "bg-customOrange text-white"
                    : "bg-gray-300 text-gray-500"
                }`}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RestaurantProfile;
