import { DocumentUpload } from "iconsax-react";
import CancelButton from "../../components/CancelButton/CancelButton";
import CreateButton from "../../components/CreateButton/CreateButton";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { Category } from "../../redux/slices/categorySlice";
import axios from "axios";
import { IoIosArrowDown } from "react-icons/io";
import {
  createFoodWithImage,
  setError,
} from "../../redux/slices/addNewFoodSlice";
import { createDailyOffer } from "../../redux/slices/addNewDailyOffer";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {};

const KrijoMenu = (props: Props) => {
  const categoryDropdownRef = useRef<HTMLDivElement | null>(null);
  const restaurant = useSelector((state: RootState) => state.restaurantProfile);
  const { error } = useSelector((state: RootState) => state.addNewFood);

  const dispatch = useDispatch<AppDispatch>();
  const [isSelected, setIsSelected] = useState<string>(
    () => localStorage.getItem("selectedTab") || "tegjitha"
  );

  const [categories, setCategories] = useState<Category[]>([]);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<number[]>([]);

  const [foodName, setFoodName] = useState<string>("");
  const [price, setPrice] = useState<number | string>("");
  const [image, setImage] = useState<File | null>(null);

  const [imageName, setImageName] = useState<string>("");

  const [title, setTitle] = useState<string>("");

  const [showModal, setShowModal] = useState(false);

  const toggleCategoryDropdown = () => setIsCategoryOpen(!isCategoryOpen);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          categoryDropdownRef.current &&
          !categoryDropdownRef.current.contains(event.target as Node)
        ) {
          setIsCategoryOpen(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const categoriesResponse = await axios.get(
          "https://dev.api.jomuntu.com/api/v1/categories?size=100",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCategories(categoriesResponse.data.content);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  const handleTabClick = (tab: string) => {
    setIsSelected(tab);
    localStorage.setItem("selectedTab", tab); // Save the selected tab to localStorage
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedImage = e.target.files[0];
      setImage(selectedImage);
      setImageName(selectedImage.name);
    }
  };

    const handleCategorySelect = (id: number) => {
      setSelectedCategory(
        (prevCategories) =>
          prevCategories.includes(id)
            ? prevCategories.filter((categoryId) => categoryId !== id)
            : [...prevCategories, id] // Add if not selected
      );
    };

  const handleSubmitFood = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const foodData = {
        foodName,
        price,
        categoryIds: selectedCategory,
      };

      await dispatch(createFoodWithImage(foodData, image));

      setFoodName("");
      setPrice("");
      setSelectedCategory([]);
      setImage(null);
    } catch (error) {
      console.error("Error creating food item:", error);
    }
  };

  const handleCancelFood = () => {
    setFoodName("");
    setPrice("");
    setSelectedCategory([]);
    setImage(null);
  };

  useEffect(() => {
    if (error) {
      setShowModal(true);
      dispatch(setError(error));
    }
  }, [error, dispatch]);

  const handleModalClose = () => {
    setShowModal(false);
    dispatch(setError(null));
  };

  // OFERTAT

  const handleSubmitFOffer = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const offerData = {
        title,
        price,
      };

      await dispatch(createDailyOffer(offerData, image));

      setTitle("");
      setPrice("");
      setImage(null);
      setImageName("")
    } catch (error) {
      console.error("Error creating daily offer:", error);
    }
  };

  const handleCancelOffer = () => {
    setTitle("");
    setPrice("");
    setImage(null);
  };

  

  return (
    <div className="w-full">
      <ToastContainer />
      <div className="flex gap-2">
        <button
          className={`w-[155px] h-[36px] rounded-lg text-sm ${
            isSelected === "tegjitha"
              ? "bg-customBrown text-white"
              : "bg-white text-black"
          }`}
          onClick={() => handleTabClick("tegjitha")}
        >
          Te gjitha ushqimet
        </button>
        <button
          className={`w-[155px] h-[36px] rounded-lg text-sm ${
            isSelected === "ofertat"
              ? "bg-customBrown text-white"
              : "bg-white text-black"
          }`}
          onClick={() => handleTabClick("ofertat")}
        >
          Ofertat Ditore
        </button>
      </div>
      {isSelected === "tegjitha" ? (
        <div>
          {showModal && (
            <ErrorModal message={error} onClose={handleModalClose} />
          )}
          <form onSubmit={handleSubmitFood}>
            <div className="pt-11">
              <h2 className="text-2xl font-medium">Shto nje ushqim te ri</h2>
              <p className="text-sm">
                Krijo një ushqim te ri, pasi të konfirmohen, ato do të shfaqen
                në aplikacionin Jomuntu.
              </p>
            </div>
            <div className="flex flex-col gap-1 pt-8">
              <label className="text-sm font-medium">
                Imazhi i ushqimit <span className="text-red-600">*</span>
              </label>
              <label className="w-full border-2 h-[124px] rounded-md border-dashed border-borderColor flex flex-col justify-center items-center cursor-pointer hover:border-customOrange">
                <div className="flex justify-center p-2 rounded-full bg-gray-50">
                  <DocumentUpload color="#F85417" />
                </div>
                <h1>
                  <span className="font-medium text-customOrange">
                    Click To Upload
                  </span>{" "}
                  or drag and drop
                </h1>
                <h1 className="text-sm">(Max.File size: 25 MB)</h1>
                <input
                  type="file"
                  onChange={handleImageChange}
                  name="file_upload"
                  className="hidden"
                  id="image"
                />
                {image && (
                  <p className="mt-2 text-sm text-gray-500">{`Selected image: ${imageName}`}</p>
                )}
              </label>
            </div>
            <div className="flex gap-3 pt-8">
              <div className="flex flex-col w-4/12 gap-1">
                <label className="text-sm font-medium">
                  Ushqimi <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  value={foodName || ""}
                  onChange={(e) => setFoodName(e.target.value)}
                  placeholder="Emri i ushqimit"
                  className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none leading-[40px]"
                />
              </div>
              <div className="flex flex-col w-4/12 gap-1">
                <label className="text-sm font-medium">
                  Kategoria <span className="text-red-600">*</span>
                </label>
                <div className="relative" onClick={toggleCategoryDropdown} ref={categoryDropdownRef}>
                  <div className="border h-[40px] rounded-md pl-4 text-sm flex items-center justify-between cursor-pointer">
                    <span>
                      {selectedCategory.length > 0
                        ? selectedCategory
                            .map(
                              (id) =>
                                categories.find(
                                  (category) => category.id === id
                                )?.name
                            )
                            .join(", ")
                        : "Select Category"}
                    </span>
                    <div className="pr-3">
                      <IoIosArrowDown color="gray" />
                    </div>
                  </div>
                  {isCategoryOpen && (
                    <div className="absolute z-10 w-full mt-1 overflow-y-auto bg-white border rounded-md shadow-lg max-h-60">
                      {categories.map((category) => (
                        <div
                          key={category.id}
                          onClick={() => handleCategorySelect(category.id)}
                          className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                        >
                          {category.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col w-4/12 gap-1">
                <label className="text-sm font-medium">
                  Qmimi <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  value={price || ""}
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder="$"
                  className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none leading-[40px]"
                />
              </div>
            </div>
            <div className="flex gap-3 pt-8">
              <div className="flex flex-col w-6/12 gap-1">
                <label className="text-sm font-medium">
                  Adresa <span className="text-red-600">*</span>
                </label>
                <input
                  readOnly
                  type="text"
                  value={restaurant.location}
                  placeholder="Adresa e restaurantit"
                  className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none leading-[40px]"
                />
              </div>
              <div className="flex flex-col w-6/12 gap-1">
                <label className="text-sm font-medium">
                  Numri Kontaktues <span className="text-red-600">*</span>
                </label>
                <input
                  readOnly
                  type="text"
                  value={restaurant.phoneNumber}
                  placeholder="+383 //////"
                  className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none leading-[40px]"
                />
              </div>
            </div>
            <div className="flex justify-end w-full gap-2 pt-24">
              <CreateButton label="DERGO PER KONFIRMIM" className="w-[236px]" />
              <CancelButton onClick={handleCancelFood} />
            </div>
          </form>
        </div>
      ) : isSelected === "ofertat" ? (
        /////////// OFERTAT DITORE ///////////////////
        <div>
          <form onSubmit={handleSubmitFOffer}>
            <div className="pt-11">
              <h2 className="text-2xl font-medium">Shto nje ofert te re</h2>
              <p className="text-sm">
                Krijo një ofert te re, pasi të konfirmohen, ato do të shfaqen në
                aplikacionin Jomuntu.
              </p>
            </div>
            <div className="flex flex-col gap-1 pt-8">
              <label className="text-sm font-medium">
                Imazhi i ushqimit <span className="text-red-600">*</span>
              </label>
              <label className="w-full border-2 h-[124px] rounded-md border-dashed border-borderColor flex flex-col justify-center items-center cursor-pointer hover:border-customOrange">
                <div className="flex justify-center p-2 rounded-full bg-gray-50">
                  <DocumentUpload color="#F85417" />
                </div>
                <h1>
                  <span className="font-medium text-customOrange">
                    Click To Upload
                  </span>{" "}
                  or drag and drop
                </h1>
                <h1 className="text-sm">(Max.File size: 25 MB)</h1>
                <input
                  type="file"
                  onChange={handleImageChange}
                  name="file_upload"
                  className="hidden"
                  id="image"
                />
                {image && (
                  <p className="mt-2 text-sm text-gray-500">{`Selected image: ${imageName}`}</p>
                )}
              </label>
            </div>
            <div className="flex gap-3 pt-8">
              <div className="flex flex-col w-6/12 gap-1">
                <label className="text-sm font-medium">
                  Emri i Ofertes <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Emri i ofertes"
                  className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none leading-[40px]"
                />
              </div>
              <div className="flex flex-col w-6/12 gap-1">
                <label className="text-sm font-medium">
                  Qmimi <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder="$"
                  className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none leading-[40px]"
                />
              </div>
            </div>
            <div className="flex gap-3 pt-8">
              <div className="flex flex-col w-6/12 gap-1">
                <label className="text-sm font-medium">
                  Adresa <span className="text-red-600">*</span>
                </label>
                <input
                  readOnly
                  type="text"
                  value={restaurant.location}
                  placeholder="Adresa e restaurantit"
                  className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none leading-[40px]"
                />
              </div>
              <div className="flex flex-col w-6/12 gap-1">
                <label className="text-sm font-medium">
                  Numri Kontaktues <span className="text-red-600">*</span>
                </label>
                <input
                  readOnly
                  type="text"
                  value={restaurant.phoneNumber}
                  placeholder="+383 //////"
                  className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none leading-[40px]"
                />
              </div>
            </div>
            <div className="flex justify-end w-full gap-2 pt-24">
              <CreateButton label="DERGO PER KONFIRMIM" className="w-[236px]" />
              <CancelButton onClick={handleCancelOffer} />
            </div>
          </form>
        </div>
      ) : null}
    </div>
  );
};

export default KrijoMenu;
