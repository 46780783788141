import React, { useEffect, useState } from "react";
import EditButton from "../../components/EditButton/EditButton";
import DeleteButton from "../../components/DeleteButton/DeleteButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { fetchMyDailyOffers } from "../../redux/slices/myDailyOffers";
import { fetchMyFoodItems } from "../../redux/slices/myFoodItems";
import DeleteModal from "../../components/Deletemodal/DeleteModal";
import { deleteFoodItem } from "../../redux/actions/allPostsActions";
import EditFoodItem from "../../components/EditFoodItem/EditFoodItem";
import { deleteDailyOffer } from "../../redux/slices/addNewDailyOffer";
import EditDailyOffer from "../../components/EditDailyOffer/EditDailyOffer";
import { ToastContainer } from "react-toastify";
import { Spin } from "antd";

type Props = {};

const MonitorimiKerkesave = (props: Props) => {
  const [selectedTable, setSelectedTable] = useState<"all" | "offers">("all");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<number | null>(null);
  const [offerToDelete, setOfferToDelete] = useState<number | null>(null)
  const [isEditScreenVisible, setIsEditScreenVisible] =
    useState<boolean>(false);
  const [itemToEdit, setItemToEdit] = useState<any | null>(null);
  const [offerToEdit, setOfferToEdit] = useState<any | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { data, loading } = useSelector(
    (state: RootState) => state.myDailyOffers
  );

  const { foodItems, loadingFood } = useSelector(
    (state: RootState) => state.myFoodItems
  );

  // useEffect(() => {
  //   dispatch(fetchMyDailyOffers())
  //   dispatch(fetchMyFoodItems())
  // },[dispatch])

  useEffect(() => {
    if (selectedTable === "offers") {
      dispatch(fetchMyDailyOffers());
    }
  }, [selectedTable, dispatch]);

  useEffect(() => {
    if (selectedTable === "all") {
      dispatch(fetchMyFoodItems());
    }
  }, [selectedTable, dispatch]);

  const handleTableSwitch = (table: "all" | "offers") => {
    setSelectedTable(table);
  };

  const openDeleteModal = (itemId: number) => {
    setItemToDelete(itemId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setItemToDelete(null);
  };

  const confirmDelete = async () => {
    if (itemToDelete !== null) {
      try {
        await dispatch(deleteFoodItem(itemToDelete.toString()));
        dispatch(fetchMyFoodItems())
        closeDeleteModal();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    }
  };

  const handleEdit = (item: any) => {
    setItemToEdit(item); // Set the item to be edited
    setIsEditScreenVisible(true); // Show the edit screen
  };

  const handleBackToMainScreen = () => {
    dispatch(fetchMyFoodItems());
    setIsEditScreenVisible(false);
    setItemToEdit(null); // Reset the item being edited
    setOfferToEdit(null);
  };

  // OFERTAT

  const openDeleteOfferModal = (offerId: any) => {
    setOfferToDelete(offerId)
    setIsDeleteModalOpen(true);
  };

  const closeDeleteOfferModal = () => {
    setIsDeleteModalOpen(false)
    setOfferToDelete(null)
  }

  const confirmOfferDelete = async () => {
    if (offerToDelete !== null) {
      try {
        await dispatch(deleteDailyOffer(offerToDelete));
        dispatch(fetchMyDailyOffers())
        closeDeleteModal();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    }
  };

  const handleEditOffer = (offer: any) => {
    setOfferToEdit(offer);  // Set the offer to be edited
    setIsEditScreenVisible(true);  // Show the edit screen for offers
  };

  return (
    <div>
      <ToastContainer/>
      {isEditScreenVisible && (itemToEdit || offerToEdit) ? (
        // Render the Edit Screen for Food Items or Offers
        offerToEdit ? (
          <EditDailyOffer
            offer={offerToEdit}
            onCancel={handleBackToMainScreen}
            onSave={handleBackToMainScreen}
          />
        ) : (
          <EditFoodItem
            item={itemToEdit}
            onCancel={handleBackToMainScreen}
            onSave={handleBackToMainScreen}
          />
        )
      ) : (
        <div>
          <div className="pt-8">
            <h2 className="text-2xl font-medium ">Kerkesat shqyrtuese</h2>
            <p className="text-sm ">
              Këtu mund të shihni listën e kërkesave të miratuara, të refuzuara
              dhe në pritje.
            </p>
          </div>
          <div className="flex gap-2 pt-12">
            <button
              className={`w-[155px] h-[36px] rounded-lg text-sm ${
                selectedTable === "all"
                  ? "bg-customBrown text-white"
                  : "bg-white text-black"
              }`}
              onClick={() => handleTableSwitch("all")}
            >
              Gjitha ushqimet
            </button>
            <button
              className={`w-[155px] h-[36px] rounded-lg text-sm ${
                selectedTable === "offers"
                  ? "bg-customBrown text-white"
                  : "bg-white text-black"
              }`}
              onClick={() => handleTableSwitch("offers")}
            >
              Ofertat Ditore
            </button>
          </div>
          {selectedTable === "all" ? (
            <div>
              {/* Table for Gjitha ushqimet */}
              <table className="w-full mt-12 table-auto">
                <thead>
                  <tr className="h-[40px] border-b w-full">
                    <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                      Imazhi i ushqimit
                    </th>
                    <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                      Emri i ushqimit
                    </th>
                    <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                      Qmimi
                    </th>
                    <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                      Kategoria
                    </th>
                    <th className="pl-6 text-sm font-medium text-left text-gray-500">
                      Statusi
                    </th>
                    <th className="border-r-2"></th>
                  </tr>
                </thead>
                <tbody>
                  {loadingFood ? (
                    <tr>
                       <td colSpan={8} className="text-center py-12">
                         <div className="flex justify-center items-center w-full h-full">
                           <Spin size="large" />
                         </div>
                       </td>
                     </tr>
                  ) : (
                    foodItems.map((item, index) => (
                      <tr
                        key={item.id}
                        className={`h-[56px] border-b ${
                          index % 2 === 0 ? "bg-gray-50" : "bg-white"
                        }`}
                      >
                        <td className="pl-6 text-sm font-normal text-left border-r-2">
                          <img
                            src={item.imageUrl}
                            alt={item.foodName}
                            className="h-[36px] w-[43px]"
                          />
                        </td>
                        <td className="pl-6 text-sm font-normal text-left border-r-2">
                          {item.foodName}
                        </td>
                        <td className="pl-6 text-sm font-normal text-left border-r-2">
                          {item.price}€
                        </td>
                        <td className="pl-6 text-sm font-normal text-left border-r-2">
                          {item.categories.join(", ")}
                        </td>
                        <td
                          className={`pl-6 text-sm font-normal text-left ${
                            item.status === "APPROVED"
                              ? "text-[#3D9F1D]"
                              : item.status === "DECLINED"
                              ? "text-[#B70707]"
                              : item.status === "PENDING"
                              ? "text-[#F56E3B]"
                              : ""
                          }`}
                        >
                          {item.status}
                        </td>
                        <td className="border-r-2">
                          {item.status !== "PENDING" && (
                            <div className="flex items-center justify-center gap-2">
                              <EditButton onClick={() => handleEdit(item)} />{" "}
                              <DeleteButton
                                onClick={() => openDeleteModal(item.id)}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {/* Delete Modal */}
              <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={closeDeleteModal}
                onConfirm={confirmDelete}
              />
            </div>
          ) : (
            <div>
              {/* Table for Ofertat Ditore */}

              <table className="w-full mt-12 table-auto">
                <thead>
                  <tr className="h-[40px] border-b w-full">
                    <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                      Imazhi i ushqimit
                    </th>
                    <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                      Emri i ushqimit
                    </th>
                    <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                      Qmimi
                    </th>
                    <th className="pl-6 text-sm font-medium text-left text-gray-500">
                      Statusi
                    </th>
                    <th className="border-r-2 "></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                    <td colSpan={8} className="text-center py-12">
                      <div className="flex justify-center items-center w-full h-full">
                        <Spin size="large" />
                      </div>
                    </td>
                  </tr>
                  ) : (
                    data.map((offer, index) => (
                      <tr
                        key={offer.id}
                        className={`h-[56px] border-b ${
                          index % 2 === 0 ? "bg-gray-50" : "bg-white"
                        }`}
                      >
                        <td className="pl-6 text-sm font-normal text-left border-r-2">
                          <img
                            src={offer.imageUrl}
                            alt={offer.title}
                            className="h-[36px] w-[43px]"
                          />
                        </td>
                        <td className="pl-6 text-sm font-normal text-left border-r-2">
                          {offer.title}
                        </td>
                        <td className="pl-6 text-sm font-normal text-left border-r-2">
                          {offer.price}€
                        </td>
                        <td
                          className={`pl-6 text-sm font-normal text-left ${
                            offer.status === "APPROVED"
                              ? "text-[#3D9F1D]"
                              : offer.status === "DECLINED"
                              ? "text-[#B70707]"
                              : offer.status === "PENDING"
                              ? "text-[#F56E3B]"
                              : ""
                          }`}
                        >
                          {offer.status}
                        </td>
                        <td className="border-r-2">
                          {offer.status !== "PENDING" && (
                            <div className="flex items-center justify-center gap-2">
                              <EditButton onClick={() => handleEditOffer(offer)}/> <DeleteButton onClick={() => openDeleteOfferModal(offer.id)} />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={closeDeleteOfferModal}
                onConfirm={confirmOfferDelete}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MonitorimiKerkesave;
