import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFoodItems,
  deleteFoodItem,
} from "../../redux/actions/allPostsActions";
import { RootState, AppDispatch } from "../../redux/store";
import DeleteModal from "../../components/Deletemodal/DeleteModal";
import { FoodItem } from "../../redux/slices/allPostsSlice";
import { LuTrash } from "react-icons/lu";
import { Spin } from "antd"; // Import the Spin component from Ant Design

const PAGE_SIZE = 6;

const AllPosts: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { foodItems, loading, error, totalElements } = useSelector(
    (state: RootState) => state.allPosts
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [foodItemToDelete, setFoodItemToDelete] = useState<FoodItem | null>(
    null
  );

  useEffect(() => {
    dispatch(fetchFoodItems({ page: currentPage - 1, size: PAGE_SIZE }));
  }, [dispatch, currentPage]);

  const totalPages = Math.ceil(totalElements / PAGE_SIZE);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const openDeleteModal = (item: FoodItem) => {
    setFoodItemToDelete(item);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setFoodItemToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const confirmDelete = async () => {
    if (foodItemToDelete) {
      await dispatch(deleteFoodItem(foodItemToDelete.id));
      closeDeleteModal();
      dispatch(fetchFoodItems({ page: currentPage - 1, size: PAGE_SIZE }));
    }
  };

  const filteredFoodItems = foodItems.filter(
    (item: FoodItem) =>
      item.foodName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.restaurantName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.location.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="flex w-full">
      <div className="flex flex-col items-center flex-grow bg-white">
        <div className="w-full">
          <div className="pt-8">
            <h1 className="text-2xl font-medium">All posts</h1>
            <p className="text-sm">
              In this section you can see all posts, edit or delete them.
            </p>
          </div>
          <div className="flex justify-between mt-4">
            <div className="flex items-end">
              <h1 className="font-medium">List:</h1>
            </div>
            <div className="relative w-[320px]">
              <input
                type="search"
                className="w-full h-[40px] border-2 rounded-lg pl-9 text-sm pr-2 outline-none"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <CiSearch
                className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2"
                size={16}
              />
            </div>
          </div>

          <div className="mt-6">
            {/* <Spin spinning={loading} size="large" className="flex justify-center items-center w-full"> */}
            <table className="w-full table-auto">
              <thead>
                <tr className="h-[40px] border-b w-full">
                  <th className="pl-6 pr-3 text-sm font-medium text-left text-gray-500 border-r-2">
                    Imazhi
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                    Food Name
                  </th>
                  <th className="pl-6 pr-3 text-sm font-medium text-left text-gray-500 border-r-2">
                    Restaurant Name
                  </th>
                  <th className="px-6 text-sm font-medium text-left text-gray-500 border-r-2">
                    Price
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                    Category
                  </th>
                  <th className="pl-6 pr-3 text-sm font-medium text-left text-gray-500 border-r-2">
                    Contact Number
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                    Location
                  </th>
                  <th className="w-[136px]"></th>
                </tr>
              </thead>
              <tbody className="relative">
                {loading ? (
                    <tr>
                      <td colSpan={8} className="text-center py-12">
                        <div className="flex justify-center items-center w-full h-full">
                          <Spin size="large" />
                        </div>
                      </td>
                    </tr>
                ) : (
                  filteredFoodItems.map((item: FoodItem, index) => (
                    <tr
                      key={item.id}
                      className={`h-[56px] border-b ${
                        index % 2 === 0 ? "bg-gray-50" : "bg-white"
                      }`}
                    >
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        <img
                          className="h-[36px] w-[43px]"
                          alt="food"
                          src={item.imageUrl}
                        />
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        {item.foodName}
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        {item.restaurantName}
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        {item.price}$
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        {item.categories.join(", ")}
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        {item.contactNumber}
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        {item.location}
                      </td>
                      <td className="w-[136px]">
                        <div className="flex justify-center gap-2">
                          <button
                            className="border w-[40px] h-[40px] flex justify-center items-center rounded-lg border-gray-200 hover:bg-gray-50 bg-white"
                            onClick={() => openDeleteModal(item)}
                          >
                            <LuTrash size={16} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {/* Pagination Controls */}
          <div className="flex gap-2 mt-4 justify-left">
            <button
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              className="border rounded-lg disabled:opacity-50 disabled:bg-[#E9ECEF] w-[40px] h-[40px] flex justify-center items-center"
            >
              <IoIosArrowBack size={10} />
            </button>

            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`w-[40px] h-[40px] flex justify-center items-center border rounded-lg text-sm ${
                  currentPage === index + 1 ? "border-black" : "text-gray-700"
                }`}
              >
                {index + 1}
              </button>
            ))}

            <button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className="w-[40px] h-[40px] flex justify-center items-center border rounded-lg disabled:opacity-50 disabled:bg-[#E9ECEF]"
            >
              <IoIosArrowForward size={10} />
            </button>
          </div>
        </div>
      </div>

      {/* Delete Modal */}
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
        categoryName={foodItemToDelete ? foodItemToDelete.foodName : ""}
      />
    </div>
  );
};

export default AllPosts;
